var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calculator" },
    [
      _c("AppTopbar", {
        attrs: {
          title: "Dispute Details",
          buttonText: "New Order",
          buttonIconClass: "icon-add",
          buttonLink: "/new"
        }
      }),
      _c("div", { staticClass: "container-fluid pt-70 pt-md-98" }, [
        _c("div", { staticClass: "card mt-15 mb-30" }, [
          _c("div", { staticClass: "card__title" }, [
            _c("div", { staticClass: "row col-md-12" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("span", { staticClass: "fs20 lh24 fw500 align-center" }, [
                  _vm._v(
                    " Dispute Details for : " + _vm._s(_vm.ref_number) + " "
                  )
                ])
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "card__content" },
            [
              _c("b-table", {
                attrs: {
                  fields: _vm.fields,
                  items: _vm.items,
                  "sticky-header": "",
                  "no-border-collapse": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(status)",
                    fn: function(data) {
                      return [
                        _c(
                          "span",
                          { class: _vm._f("lowercase")(data.item.status) },
                          [_vm._v(_vm._s(data.item.status))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c(
                "div",
                { staticClass: "col pt-15 fs20 lh24 fw500 align-center" },
                [
                  _vm._v(
                    " Provisional Hold Amount : ₹" + _vm._s(_vm.hold_amt) + " "
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "col pt-20 fs20 lh24 fw500 align-center" },
                [
                  _vm._v(
                    " Final Hold Amount : ₹" + _vm._s(_vm.final_hold_amt) + " "
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }