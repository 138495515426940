    <template>
    	<div class="calculator">
    		<AppTopbar
    		title="Dispute Details"
    		buttonText="New Order"
    		buttonIconClass="icon-add"
    		buttonLink="/new"
    		/>

	


<div class="container-fluid pt-70 pt-md-98">
	

	<div class="card mt-15 mb-30">
		<div class="card__title" >
			<div class="row col-md-12">
				<div class="col-md-6">
					<span class="fs20 lh24 fw500 align-center">
						Dispute Details for : {{ref_number}}
					</span>
				</div>
			</div>
			
		</div>
		<div class="card__content">
			<b-table :fields="fields" :items="items" sticky-header no-border-collapse>

				<template v-slot:cell(status)="data">
		            <span :class="data.item.status | lowercase">{{data.item.status}}</span>
		         </template>
			</b-table>
			<div class="col pt-15 fs20 lh24 fw500 align-center">
				Provisional Hold Amount : ₹{{hold_amt}}
			</div>
			<div class="col pt-20 fs20 lh24 fw500 align-center">
				Final Hold Amount : ₹{{final_hold_amt}}
			</div>
		</div>
	</div>
	
</div>
</div>

</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import AppTopbar from "@/components/layout/AppTopbar.vue";
import { mapState } from "vuex";

export default {
	name: "Dispute_Details",
	title: "Dispute Detail",
	data() {
		return {
			ref_number:'',
			fields: [
		        { key: "order_id", label: "ORDER-ID" ,thClass: "bg-grey text-center",tdClass:"mid-align text-center"},
		        { key: "awb", label: "AWB" ,thClass: "bg-grey text-center",tdClass:"mid-align text-center"},
		        { key: "bill_type", label: "BILL-TYPE" ,thClass: "bg-grey text-center",tdClass:"mid-align text-center"},
		        { key: "carrier", label: "CARRIER" ,thClass: "bg-grey text-center",tdClass:"mid-align text-center"},
		        { key: "mode", label: "MODE" ,thClass: "bg-grey text-center",tdClass:"mid-align text-center"},
		        { key: "zone", label: "ZONE" ,thClass: "bg-grey text-center",tdClass:"mid-align text-center"},
		        { key: "product", label: "PRODUCT" ,thClass: "bg-grey text-center",tdClass:"mid-align text-center"},
		        { key: "declared_wt", label: "DECLARED WT." ,thClass: "bg-grey text-center",tdClass:"mid-align text-center"},
		        { key: "charged_wt", label: "CHARGED WT." ,thClass: "bg-grey text-center",tdClass:"mid-align text-center"},
		        { key: "claimed_wt", label: "CLAIMED WT." ,thClass: "bg-grey text-center",tdClass:"mid-align text-center"},
	        	{ key: "charged_amt", label: "CHARGED AMT." ,thClass: "bg-grey text-center",tdClass:"mid-align text-center"},
	        	{ key: "disputed_amt", label: "DISPUTED AMT." ,thClass: "bg-grey text-center",tdClass:"mid-align text-center"},
	        	{ key: "remark", label: "REMARKS" ,thClass: "bg-grey",tdClass:"mid-align lastChild"},
	        	{ key: "status", label: "STATUS" ,thClass: "bg-grey",tdClass:"mid-align"},
		      ],
	      	items:[],
	      	form:{
				file:null
	      	},
			orderType: null,
			options: ["Prepaid", "Cod", "Reverse"],
			form:{},
			calcData:[],
			calcForm:[],
			autocalcForm:[],
			pincode:null,
			pincode1:null,
			totalValue:false,
			serviceTab:[],
			pricingTab:[],
			NCtable:false,
			uploadImages:[],
			maxLength:200,
			comments:'',
			toggleDispute:false,
			hold_amt:'',
			final_hold_amt:'',
			form2:{
				file:null
			},
			excelData:[],
			errorText:''
		};
	},
	components: {
		AppTopbar,
		Multiselect
	},
	computed:{
		isComplete() {
			if(this.form.orderType || this.form.orderType2){return true} else {return false}
		},
	...mapState(["user"])
},
mounted() {
	this.ref_number = this.$route.params.id
	this.getDisputed();
},
methods: {
	async getDisputed(){
		const id = this.ref_number
		try {
			const res = await this.axios.get(`/billing/getdisputedetails/${id}`)
			if (res.data.status) {
				this.hold_amt = res.data.provisionalHoldAmount
				this.final_hold_amt = res.data.totalFinalHoldAmount
				res.data.result.forEach((item,index)=> {
        			this.items.push({
        				order_id:item.orderID,
	        			awb:item.awb,
	        			bill_type:item.billType,
	        			carrier:item.carrierValue,
	        			mode:item.modeValue,
	        			zone:item.zone,
	        			product:item.product,
	        			declared_wt:item.declearWeight,
	        			charged_wt:item.chargedWeight,
	        			claimed_wt:item.weight,
	        			charged_amt:item.chargedAmount,
	        			disputed_amt:item.disputedAmount,
	        			status:item.disputeStatusType,
	        			statusColor:item.disputeStatusColor,
	        			remark:item.comment
        			})
        		})
			} else {
				// statement
			}
		} catch(e) {
			this.popToast('failed','Failed',"Data Fetching Failed!! Please Try again Later")
			console.log(e);
		}
	}
},
};
</script>

<style scoped type="text/scss">
/deep/ .b-table-sticky-header {
	max-height: unset !important;
}
.rejected {
	color: #FF0000 !important;
}
.pending {
	color: #0000FF !important;
}
.escalated {
	color: #00FF00 !important;
}
.closed {
	color: #000000 !important;
}
.tick {
    height: 1.7rem;
    width: 1.8rem;
}
.card__content .info-card li:before {
	content: unset !important;
}
.first-title {
	justify-content: space-between !important;
}
.sellerAddressLine {
	height: 8rem !important;
	width: 34rem !important;
}
.table_dispute {
	background: white !important;
}
/deep/ .bg-grey {
	background-color: #f4f7fa !important;
}
/deep/ .mid-align {
	vertical-align: middle !important;
}
/deep/ .lastChild {
	max-width: 10rem !important;
}
</style>